<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'

export default {
  props: {
    xAxisData: {
      type: Array,
      default() {
        return []
      }
    },
    chartData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    VChart: VChart
  },
  data() {
    return {
      opt: {
        color: ['#ef2b23'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          name: this.$t('adminHomePage.unit') + ':' + this.$t('customerHome.second'),
          type: 'value',
          minInterval: 1
        },
        series: {
          name: this.$t('customerStatistical.install'),
          type: 'line',
          data: []
        }
      }
    }
  },
  created() {
    this.opt.xAxis.data = this.xAxisData
    this.opt.series.data = this.chartData
  },

  watch: {
    chartData(val) {
      this.opt.series.data = val
    },
    xAxisData(val) {
      this.opt.xAxis.data = val
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  flex: 1;
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
